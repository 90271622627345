<template>
    <main>
        <page-header title="Crear Servicio"></page-header>
        <div class="container-fluid mt-n10">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 offset-3">
                            <form>
                                <div class="form-group">
                                    <label class="small mb-1">Codigo</label>
                                    <input type="text"
                                           class="form-control form-control-sm"
                                           v-model.trim="$v.servicio.codigo.$model"
                                           :class="{
                                               'is-invalid' : $v.servicio.codigo.$error,
                                               'is-valid' : !$v.servicio.codigo.$error && $v.servicio.codigo.$dirty
                                           }"
                                    >
                                    <span class="invalid-feedback" v-if="!$v.servicio.codigo.required">Este campo es requerido</span>
                                    <span class="invalid-feedback" v-if="!$v.servicio.codigo.isUnique">El codigo ingresado ya se encuentra registrado</span>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1">Descripcion</label>
                                    <input type="text"
                                           class="form-control form-control-sm"
                                           v-model="servicio.descripcion"
                                           :class="{'is-invalid' : $v.servicio.descripcion.$error}"
                                    >
                                    <span class="invalid-feedback" v-if="!$v.servicio.descripcion.required">Este campo es requerido</span>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1">Agrupacion</label>
                                    <v-select-agrupacion v-model="servicio.id_agrupacion"
                                                         :validator="$v.servicio.id_agrupacion" :class="{'is-invalid' : $v.servicio.id_agrupacion.$error}">
                                    </v-select-agrupacion>
                                    <span class="invalid-feedback" v-if="!$v.servicio.id_agrupacion.required">Este campo es requerido</span>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1">Sexo</label>
                                    <select class="form-control form-control-sm" v-model="servicio.sexo"
                                            :class="{'is-invalid' : $v.servicio.sexo.$error}">
                                        <option v-for="(sexo, i) in sexos" :key="`option_${i}`" :value="sexo.codigo">{{sexo.descripcion}}</option>
                                    </select>
                                    <span class="invalid-feedback" v-if="!$v.servicio.sexo.required">Este campo es requerido</span>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1">Medicamento</label>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="es_medicamento_no" value="C" v-model="servicio.id_tarifa" name="es_medicamento" class="custom-control-input">
                                        <label class="custom-control-label" for="es_medicamento_no">No</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="es_medicamento_si" value="M" v-model="servicio.id_tarifa" name="es_medicamento" class="custom-control-input">
                                        <label class="custom-control-label" for="es_medicamento_si">Si</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1">RIPS</label>
                                    <select class="form-control form-control-sm"
                                            v-model="servicio.tipo_rip"
                                            :class="{'is-invalid' : $v.servicio.tipo_rip.$error}">
                                        <option v-for="(t, i) in tipos_rips" :key="`o_${i}`" :value="t.codigo">{{t.descripcion}}</option>
                                    </select>
                                    <span class="invalid-feedback" v-if="!$v.servicio.tipo_rip.required">Este campo es requerido</span>
                                </div>
                                <div class="form-group d-flex justify-content-end">
                                    <button class="btn btn-success btn-sm mb-2" type="button" @click.prevent="save()"> Guardar </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import PageHeader from "../../../components/layouts/content/page-header";
import VSelectAgrupacion from "../../../components/common/VSelectAgrupacion";
import Swal from 'sweetalert2/src/sweetalert2';
import ServicioService from "../../../services/servicioService";
import { required } from "vuelidate/lib/validators";

export default {
    components: {VSelectAgrupacion, PageHeader},
    data () {
        return {
            old_code : '',
            servicio : {
                id : '',
                id_agrupacion : '',
                codigo : '',
                descripcion : '',
                id_tarifa : 'C',
                tipo_rip : '',
                sexo : '',
            },
            tipos_rips : [
                {
                    codigo : 1,
                    descripcion : 'Consulta'
                },
                {
                    codigo : 2,
                    descripcion : 'Procedimiento'
                },
                {
                    codigo : 3,
                    descripcion : 'Materiales e insumos'
                },
                {
                    codigo : 4,
                    descripcion : 'Medicamentos'
                },

            ],
            sexos : [
                {
                    codigo : 'F',
                    descripcion: "Femenino"
                },
                {
                    codigo : 'M',
                    descripcion: "Masculino"
                },
                {
                    codigo : 'A',
                    descripcion: "Ambos Sexos"
                }
            ]
        }
    },
    validations : {
        servicio : {
            id_agrupacion : {required},
            codigo : {
                required,
                async isUnique (value) {
                    if(value === '' || this.old_code === value) return true;
                    const isUnique = (await ServicioService.isUnique(value))?.data;
                    return Boolean(isUnique);
                }
            },
            descripcion : {required},
            id_tarifa : {required},
            tipo_rip : {required},
            sexo : {required},
        },
    },
    async created() {
        const id = this.$route.params.id;
        this.LoaderSpinnerShow();
        const response = (await ServicioService.show(id))?.data;
        this.old_code = response.codigo;
        this.servicio = response;
        this.LoaderSpinnerHide();
    },
    methods : {
        async save(){
            try{
                this.$v.servicio.$touch();

                if(this.$v.servicio.$invalid) return;

                this.LoaderSpinnerShow();
                await ServicioService.update(this.servicio);
                this.LoaderSpinnerHide();

                Swal.fire('Actualizado con exito', '','success');

            }catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Swal.fire('Ocurrio un error al procesar la solicitud', '', 'error');
            }
        }
    }
}
</script>

<style scoped>

</style>

